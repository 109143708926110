import { shortUuid, type Maturity } from '@tunasong/models'
import { maturityFilter, type TunaEditor, type TunaEditorConfig, type TunaPlugin } from '@tunasong/plugin-lib'
import { useThunkDispatch, useUserConfig, type RootState } from '@tunasong/redux'
import { isCoreElement, type EntityOrElement } from '@tunasong/schemas'
import { withPlate } from '@udecode/plate-core'
import { createTEditor } from '@udecode/slate'
import { useMemo, useRef } from 'react'
import { useStore } from 'react-redux'

/** Create the (raw) editor. If you manage this you need to handle the root properties (e.g., id) separately. In the normal case, use `useCreateEditor` instead */
export const createTunaEditor = <T extends TunaEditor = TunaEditor>(
  id: string,
  plugins: TunaPlugin[],
  config: TunaEditorConfig,
  maturity: Maturity
) => {
  const filteredPlugins = plugins.filter(maturityFilter(maturity))
  /** corePlugins are included with Plate, filtered by maturity */
  const ed = createTEditor()
  /** Type inference issues requires a cast here */
  const editor = withPlate(ed, {
    id,
    plugins: filteredPlugins as never,
  })

  editor.id = id as never
  editor.config = config

  return editor as unknown as T
}

// Create a new editor. It will re-create if rootElement.id or plugins change.
export const useCreateEditor = (rootElement: EntityOrElement | null, plugins: TunaPlugin[] | null) => {
  const dispatch = useThunkDispatch()
  const { getState } = useStore<RootState>()

  /** We allow only once creation per rootElement */
  const rootElementRef = useRef<EntityOrElement | null>(rootElement)

  const [maturity] = useUserConfig('featureMaturity')

  const editor = useMemo(() => {
    if (!plugins) {
      return null
    }
    const id = shortUuid()
    const editor = createTunaEditor(id, plugins, { dispatch, getState }, maturity)

    if (isCoreElement(rootElementRef.current)) {
      editor.rootElement = rootElementRef.current
      editor.children = rootElementRef.current.children ?? []
    }
    return editor
  }, [dispatch, getState, maturity, plugins])

  return editor
}
