import { Box, type BoxProps } from '@mui/material'
import { type AudioPosition } from '@tunasong/models'
import type { Audio, Media, Persisted, Video } from '@tunasong/schemas'
import { type FC, useCallback, useRef } from 'react'
import { useAudioPlayer } from './hooks/index.js'
import { PlayerControls } from './player-controls.js'

export interface CompactPlayerProps extends BoxProps {
  name?: string
  active?: Persisted<Audio | Video>
  entities: Persisted<Media>[]
  /** Capture keyboard events to control the player */
  keyboardControl?: boolean
  /** Open the media with the given index */
  onActivate(idx: number): void
}

export const CompactPlayer: FC<CompactPlayerProps> = props => {
  const { entities, active, keyboardControl = false, onActivate, ...restProps } = props

  const ref = useRef<HTMLAudioElement | null>(null)

  const { audioProps } = useAudioPlayer({ name: active?.name, el: ref.current })

  const handleSeek = useCallback((pos: AudioPosition) => {
    if (ref.current) {
      ref.current.currentTime = pos.seconds
    }
  }, [])

  const src = active?.storageUrls?.url

  return (
    <Box {...restProps}>
      <audio {...audioProps} ref={ref} controls={false} src={src} />
      <Box flex={1} />

      <PlayerControls
        sx={{ width: '100%' }}
        el={ref.current}
        media={entities}
        active={active}
        keyboardControl={keyboardControl}
        onSeek={handleSeek}
        onSelect={onActivate}
      />
    </Box>
  )
}

export default CompactPlayer
