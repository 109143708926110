import { blueGrey, green, orange } from '@mui/material/colors'
import { alpha } from '@mui/system'
import { useUserConfig } from '@tunasong/redux'
import { createTheme, ThemeProvider } from '@tunasong/ui-lib'
import React, { type FC, useEffect, useState } from 'react'

/** Theme is defined in ui-lib/styles.ts */

type ThemeType = 'light' | 'dark'
export interface TunaThemeProps {
  theme?: ThemeType
  /** @default 14 */
  textSize?: number
  children?: React.ReactNode
}

const darkBackground = {
  default: '#101010',
  paper: '#121212',
  layer: '#121212',
}

const lightBackground = {
  default: '#fff',
  paper: '#fff',
  layer: '#fafaf8',
}

export const TunaTheme: FC<TunaThemeProps> = ({ children, theme = 'light', textSize = 14 }) => {
  const muiTheme = createTheme({
    palette: {
      mode: theme,
      secondary: { main: '#e34f82' },
      background: theme === 'light' ? lightBackground : darkBackground,
    },
    notes: {
      main: blueGrey[500],
      outOfScale: orange[500],
      inScale: green[500],
    },
    layers: {
      backgroundColor: theme === 'light' ? alpha(lightBackground.layer, 0.7) : alpha(darkBackground.layer, 0.7),
    },

    typography: {
      fontSize: textSize,

      fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoj","Segoe UI Symbol";`,
    },
  })

  muiTheme.typography.caption = {
    ...muiTheme.typography.caption,

    color: muiTheme.palette.text.secondary,
  }

  muiTheme.typography.h1 = {
    marginTop: `16px`,
    marginBottom: `8px`,
    fontWeight: 500,
    // color: muiTheme.palette.text.secondary,

    [muiTheme.breakpoints.down('md')]: {
      fontSize: 32,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 40,
    },
  }
  muiTheme.typography.h2 = {
    marginTop: `16px`,
    marginBottom: `8px`,
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 28,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  }
  muiTheme.typography.h3 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  }
  muiTheme.typography.h4 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  }
  muiTheme.typography.h5 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
  }
  muiTheme.typography.h6 = {
    fontWeight: 500,

    [muiTheme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
  }

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
}

const INITIAL_THEME_KEY = 'tunasong.ui.initialtheme'

/** Depends on Redux  */
export const UserTheme: FC<TunaThemeProps> = props => {
  const [config, , hasLoaded] = useUserConfig()

  const [theme, setTheme] = useState<ThemeType>((localStorage.getItem(INITIAL_THEME_KEY) as ThemeType) ?? props.theme)

  useEffect(() => {
    const theme = config.theme
    if (!(theme && hasLoaded)) {
      return
    }
    localStorage.setItem(INITIAL_THEME_KEY, theme)
    setTheme(theme)
  }, [config.theme, hasLoaded])

  return (
    <TunaTheme {...props} theme={theme}>
      {props.children}
    </TunaTheme>
  )
}
