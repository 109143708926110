import { ElementEditor, createTunaEditor, type ElementEditorProps } from '@tunasong/editor'
import { deepEqual, shortUuid } from '@tunasong/models'
import { maturityFilter, useYDocConnect, type TunaEditor, type TunaPlugin } from '@tunasong/plugin-lib'
import { useEntityUpdate, useThunkDispatch, useUserConfig, type RootState } from '@tunasong/redux'
import { isCoreElement, isPersisted, type CoreElement, type Entity, type Persisted } from '@tunasong/schemas'
import { Awareness, Y, YjsEditor, getElementValue, type SyncProvider } from '@tunasong/sync-lib'
import { useCurrentUser, useDelayedInvoke } from '@tunasong/ui-lib'
import { setPlatePlugins } from '@udecode/plate-core'
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react'
import { useStore } from 'react-redux'
import invariant from 'tiny-invariant'
import { useYjsPlugin } from '../yjs-plugin.js'

export interface CollabEditorProps extends Omit<ElementEditorProps, 'editor' | 'element'> {
  /** We want the CollabEditor to not deal with Entities, but some plugins depend on the entityId */
  element: Entity
  doc: Y.Doc
  provider?: SyncProvider
  awareness: Awareness
  color: string
  name: string
  plugins: TunaPlugin[]
  onEditor?(editor: TunaEditor): void
}

export const CollabEditor: FC<CollabEditorProps> = ({
  /** There may be plugins that need the entityId */
  element: initialEntity,
  color,
  doc,
  provider,
  awareness,
  plugins,
  onEditor,
  ...restProps
}) => {
  const { userId } = useCurrentUser()

  invariant(isPersisted(initialEntity), 'CollabEditor requires a persisted entity')

  const [maturity] = useUserConfig('featureMaturity')

  const [entity, setEntity] = useState<Persisted<CoreElement>>()

  useEffect(() => {
    const elValue = getElementValue(doc)
    setEntity(entity => ({
      /** We need features on the top-level entity as well */
      ...initialEntity,
      children: isCoreElement(entity) ? entity.children : elValue,
    }))
  }, [initialEntity, doc])

  const { plugin: yjsPlugin } = useYjsPlugin({
    doc,
    userId,
    color,
    provider,
    awareness,
  })

  const allPlugins = useMemo(
    () => [...(plugins ?? []), yjsPlugin].filter(Boolean).filter(maturityFilter(maturity)),
    [maturity, plugins, yjsPlugin]
  )

  /**
   * @note
   * we don't use useTunaEditor() here because we need to a) create the editor once, and b) pass the plugins when yjsPlugin is updated
   * plugins must change for decorate() to be called properly, and we want useCreateEditor() to re-create when plugins change.
   */
  const editorRef = useRef<TunaEditor & YjsEditor>()
  const editor = editorRef.current
  const id = useRef(shortUuid())
  const dispatch = useThunkDispatch()
  const { getState } = useStore<RootState>()
  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = createTunaEditor<TunaEditor & YjsEditor>(
        id.current,
        allPlugins,
        { dispatch, getState },
        maturity
      )
    } else {
      /** @todo cast here due to type instantiation depth problem */
      setPlatePlugins(editorRef.current as never, { plugins: allPlugins as never })
    }
  }, [dispatch, getState, allPlugins, maturity])

  useEffect(() => {
    if (onEditor && editor) {
      onEditor(editor)
    }
  }, [editor, onEditor])

  /** Update metadata on the entity */
  const updateEntity = useEntityUpdate({ debounceDelay: 3 * 1000 })
  const delayedMetadataUpdate = useDelayedInvoke(30000, 'timeout')
  const prevMetadata = useRef(entity?.metadata)
  const handleChange = useCallback(() => {
    delayedMetadataUpdate(() => {
      if (!editor) {
        return
      }
      invariant(entity?.id, 'CollabEditor requires a persisted entity')

      const pMeta = prevMetadata.current ?? entity?.metadata
      const metadata = editor.getMetadata()
      prevMetadata.current = metadata

      // Check if the metadata has changed
      const payload = deepEqual(metadata, pMeta) ? {} : { metadata }
      updateEntity(entity.id, payload)
    })
  }, [delayedMetadataUpdate, editor, entity?.id, entity?.metadata, updateEntity])

  /** Connect the editor to the YDoc */
  useYDocConnect(editor)

  if (!(entity && editor)) {
    return null
  }

  invariant(isCoreElement(entity), 'CollabEditor requires a core element')

  return <ElementEditor key={entity.id} editor={editor} element={entity} onChange={handleChange} {...restProps} />
}

export default CollabEditor
