import { Box, type BoxProps } from '@mui/material'
import { type NoteDegree as NoteDegreeType } from '@tunasong/music-lib'
import { type FC } from 'react'

export interface NoteDegreeProps extends BoxProps {
  degree: NoteDegreeType
}

export const NoteDegree: FC<NoteDegreeProps> = ({ degree, sx, ...restProps }) => (
  <Box
    component="span"
    contentEditable={false}
    sx={{
      position: 'relative',
      color: theme => theme.palette.action.active,
      paddingLeft: 0.1,
      top: theme => theme.spacing(-0.5),
      fontSize: '0.7em',
      ...sx,
    }}
    {...restProps}
  >
    {degree}
  </Box>
)
