/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '@tunasong/ui-lib'

export const useStyles = makeStyles()(theme => ({
  clip: {
    minHeight: 100,
    position: 'relative',
    height: '100%',
  },
  clipContainer: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(1, 0, 1, 0),
    border: `1px solid ${theme.palette.action.focus}`,
    borderRadius: theme.spacing(),
    opacity: 1,
  },
  recordingClip: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    margin: theme.spacing(1, 0, 1, 0),
    backgroundColor: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.action.focus}`,
    borderRadius: theme.spacing(),
    opacity: 0.5,
  },
  recordingWav: {
    flex: 1,
    display: 'flex',
    height: '100%',
  },
  selected: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  dragging: {
    cursor: 'dragging',
    opacity: 0.5,
  },
  /** The wave container */
  waveContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
}))

export default useStyles
