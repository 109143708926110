import { Box, type SxProps, type Theme } from '@mui/material'
import Note from '@tonaljs/note'
import React, { type FC } from 'react'
interface KeyProps {
  midiNumber: number
  inScale?: boolean
  naturalKeyWidth: number // Width as a ratio between 0 and 1
  noteRange: { first: number; last: number }
  useTouchEvents?: boolean
  accidental?: boolean
  active?: boolean
  disabled?: boolean
  accidentalWidthRatio?: number
  pitchPositions?: Record<string, number>
  children: React.ReactNode
  onPlayNoteInput(note: number): void
  onStopNoteInput(note: number): void
}

const Key: FC<KeyProps> = props => {
  const {
    onPlayNoteInput,
    onStopNoteInput,
    naturalKeyWidth,
    midiNumber,
    useTouchEvents,
    accidental,
    active,
    children,
    noteRange,
    accidentalWidthRatio = 0.65,
    pitchPositions = {
      C: 0,
      Db: 0.55,
      D: 1,
      Eb: 1.8,
      E: 2,
      F: 3,
      Gb: 3.5,
      G: 4,
      Ab: 4.7,
      A: 5,
      Bb: 5.85,
      B: 6,
    },
  } = props

  const handlePlayNoteInput = () => {
    onPlayNoteInput(midiNumber)
  }

  const handleStopNoteInput = () => {
    onStopNoteInput(midiNumber)
  }

  // Key position is represented by the number of natural key widths from the left
  const getAbsoluteKeyPosition = (midiNumber: number) => {
    const OCTAVE_WIDTH = 7
    const { pc: pitchName, oct: octave = 3 } = Note.get(Note.fromMidi(midiNumber))
    const pitchPosition = pitchPositions[pitchName]
    const octavePosition = OCTAVE_WIDTH * octave
    return pitchPosition + octavePosition
  }

  const getRelativeKeyPosition = (midiNumber: number) =>
    getAbsoluteKeyPosition(midiNumber) - getAbsoluteKeyPosition(noteRange.first)

  // Need to conditionally include/exclude handlers based on useTouchEvents,
  // because otherwise mobile taps double fire events.
  const sx: SxProps<Theme> = {
    display: 'flex',
    ...(accidental
      ? {
          background: theme => (active ? theme.palette.secondary.light : '#555'),
          border: active ? '1px solid #fff' : '1px solid #fff',
          borderTop: theme => (active ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent'),
          borderRadius: '0 0 4px 4px',
          cursor: 'pointer',
          height: active ? '65%' : '66%',
          /* Overlay on top of natural keys */
          zIndex: 1,
          /* Use absolute positioning along with inline styles specified in JS to put keys in correct locations. */
          position: 'absolute',
          top: 0,
        }
      : {
          background: theme => (active ? theme.palette.secondary.light : '#f6f5f3'),
          border: theme => (active ? `1px solid ${theme.palette.secondary.main}` : '1px solid #888'),
          borderRadius: '0 0 6px 6px',
          cursor: 'pointer',
          zIndex: 0,
          height: active ? '98%' : '100%',
          /*
           * Uses flexbox with margin instead of absolute positioning to have more consistent margin rendering.
           * This causes inline styles to be ignored.
           */
          flex: 1,
          marginRight: '1px',
          '&:last-child': {
            marginRight: 0,
          },
        }),
  }

  return (
    <Box
      sx={sx}
      style={{
        left: ratioToPercentage(getRelativeKeyPosition(midiNumber) * naturalKeyWidth),
        width: ratioToPercentage(accidental ? accidentalWidthRatio * naturalKeyWidth : naturalKeyWidth),
      }}
      onMouseDown={useTouchEvents ? undefined : handlePlayNoteInput}
      onMouseUp={useTouchEvents ? undefined : handleStopNoteInput}
      onTouchStart={useTouchEvents ? handlePlayNoteInput : undefined}
      onTouchCancel={useTouchEvents ? handleStopNoteInput : undefined}
      onTouchEnd={useTouchEvents ? handleStopNoteInput : undefined}
    >
      <Box
        sx={{
          flex: 1,
          /* Align children .ReactPiano__NoteLabel to the bottom of the key */
          alignSelf: 'flex-end',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function ratioToPercentage(ratio: number) {
  return `${ratio * 100}%`
}

export default Key
