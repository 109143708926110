import { logger } from '@tunasong/models'
import { IndexeddbPersistence, Y } from '@tunasong/sync-lib'
import { useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { indexeddbCacheName } from './db.js'

interface UseDBSyncProps {
  entityId: string
  doc: Y.Doc
  disabled?: boolean
}
/** Sync from IndexedDB */
export const useDBSync = (props: UseDBSyncProps) => {
  const { doc, entityId, disabled = false } = props

  invariant(entityId, `useDBSync without entityId specified`)

  /** `true` when the doc has been synced with local persistence */
  const [localSync, setLocalSync] = useState(false)
  const idbRef = useRef<IndexeddbPersistence | null>(null)
  /** Start provider first to ensure that the SyncHandler will record changes from Indexeddb */
  useEffect(() => {
    if (disabled) {
      return
    }

    const idb = new IndexeddbPersistence({ cacheName: indexeddbCacheName(entityId), doc })
    idbRef.current = idb
    const onSynced = () => {
      setLocalSync(true)
    }

    idb.on('synced', onSynced)
    /** Cleanup when closing */
    return () => {
      idb.off('synced', onSynced)
      // eslint-disable-next-line no-underscore-dangle
      if (idb._storeTimeoutId) {
        logger.debug(`useDBSync: Pending database update. Not destroying the database.`)
      } else {
        idb.destroy()
      }
    }
  }, [disabled, doc, entityId])

  /**
   * Sync from local IndexedDB store to doc
   * If incrementalSyncCallback is provided, it will be called after each sync operation, otherwise it will sync once.
   */
  const sync = useCallback(async () => {
    if (!idbRef.current) {
      logger.error(`useDBSync: idb is not initialized`)
      return
    }
    // If incrementalSyncCallback is provided, it will be called after each sync operation
    return idbRef.current.sync()
  }, [])

  return { isSynced: localSync, sync }
}
