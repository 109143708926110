import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Accordion, AccordionDetails, type AccordionProps, AccordionSummary, Typography } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'
import { type TunaPluginRenderContextProps } from '@tunasong/plugin-lib'
import React, { type FC, useCallback, useState } from 'react'

export interface PanelProps extends Omit<AccordionProps, 'children'>, Partial<TunaPluginRenderContextProps> {
  className?: string
  title?: string
  subtitle?: string
  gutters?: boolean
  children?: React.ReactNode
}

const useStyles = makeStyles()(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    flex: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  gutters: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  noGutters: {
    padding: theme.spacing(1, 0, 0, 0),
  },
}))

const Panel: FC<PanelProps> = props => {
  const { className, gutters = true, title = 'Unknown', subtitle, defaultExpanded = true, children } = props
  const { classes } = useStyles()
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleExpand = useCallback((ev: unknown, expanded: boolean) => setExpanded(expanded), [])

  return (
    <Accordion className={className} elevation={0} variant="outlined" expanded={expanded} onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
        {subtitle ? <Typography className={classes.secondaryHeading}>{subtitle}</Typography> : null}
      </AccordionSummary>

      <AccordionDetails className={gutters ? classes.gutters : classes.noGutters}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default Panel
