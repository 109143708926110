import { IconButton, Typography, type BoxProps } from '@mui/material'
import { Next, Play, Prev, Stop } from '@tunasong/icons'
import type { AudioPosition } from '@tunasong/models'
import type { Audio, Persisted, Video } from '@tunasong/schemas'
import { HBox, VBox } from '@tunasong/ui-lib'
import { useCallback, useMemo, useRef, useState, type FC } from 'react'
import { useTimelineInteraction } from './hooks/index.js'
import { usePlayerControl } from './player-control.hook.js'
import PlayerProgress from './progress.js'

export interface PlayerButtonProps extends BoxProps {
  audio: Persisted<Video | Audio> | Persisted<Video | Audio>[]
  /** Hide progress and name */
  compact?: boolean
}

export const PlayerButton: FC<PlayerButtonProps> = props => {
  const { audio, compact = false, ...restProps } = props

  const ref = useRef<HTMLAudioElement | null>(null)

  const audioList = useMemo(() => (Array.isArray(audio) ? audio : [audio]), [audio])

  const [currentAudio, setCurrentAudio] = useState<Persisted<Audio | Video>>(audioList[0])
  const handleSelect = useCallback((idx: number) => setCurrentAudio(audioList[idx]), [audioList])

  const { audioProps, next, prev, togglePlay, seek } = usePlayerControl({
    name: currentAudio?.name,
    el: ref.current,
    media: audioList,
    active: currentAudio,
    onSelect: handleSelect,
  })

  const src = currentAudio?.storageUrls?.url

  const { timelineRef } = useTimelineInteraction({
    duration: ref.current?.duration,
    onSeek: (pos: AudioPosition) => seek(pos.seconds),
  })

  const size = compact ? 'small' : 'medium'
  const showControls = audioList.length > 1

  return (
    <VBox {...restProps}>
      <HBox sx={{ alignItems: 'center' }}>
        <audio {...audioProps} ref={ref} controls={false} src={src} crossOrigin="anonymous" />
        {showControls ? (
          <IconButton disabled={!prev} onClick={prev} color="primary" size={size}>
            <Prev />
          </IconButton>
        ) : null}
        <IconButton
          disabled={!audio}
          onClick={togglePlay}
          color="primary"
          size={size}
          title={currentAudio?.name ?? 'Not playing'}
        >
          {ref.current?.paused ? <Play /> : <Stop />}
        </IconButton>
        {showControls ? (
          <IconButton disabled={!next} onClick={next} color="primary" size={size}>
            <Next />
          </IconButton>
        ) : null}
        {audio && !compact ? <PlayerProgress sx={{ ml: 2 }} ref={timelineRef} el={ref.current} /> : null}
      </HBox>
      <Typography variant="caption" color="primary">
        {currentAudio?.name}
      </Typography>
    </VBox>
  )
}

export default PlayerButton
