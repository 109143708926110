import { Button, Typography } from '@mui/material'
import { useEntitiesById } from '@tunasong/redux'
import type { Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useRecent } from '@tunasong/search'
import { EntityGrid, HBox, useDimensions, useNavigateToEntity } from '@tunasong/ui-lib'
import { type FC, useCallback, useState } from 'react'

export interface RecentProps {
  /** Entities to exclude from the list, e.g., if they are already shown */
  exclude?: Persisted<Entity>[]
  /** Title of the recent list */
  title?: string
}

export const Recent: FC<RecentProps> = props => {
  const { exclude, title } = props
  const navigateToEntity = useNavigateToEntity()
  const { isTiny } = useDimensions()
  const steps = isTiny ? 2 : 3
  const [rows, setRows] = useState(1)

  const { isLoading, searchResults: recentEntities } = useRecent({ exclude, maxCount: 15 })
  const { entities } = useEntitiesById(recentEntities.slice(0, rows * steps).map(e => e.id))
  const adjustRows = useCallback((num: number) => () => setRows(rows => rows + num), [])

  return (
    <>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}
      <EntityGrid
        sx={{ py: theme => theme.spacing(1) }}
        placeholder="No recent activities found"
        variant="small"
        entities={entities}
        onOpen={navigateToEntity}
        loading={isLoading}
      />
      <HBox sx={{ justifyContent: 'flex-end' }}>
        <Button disabled={rows === 1} onClick={adjustRows(-1)} size="small" color="info">
          -
        </Button>
        <Button disabled={rows * steps >= recentEntities.length} onClick={adjustRows(1)} size="small" color="info">
          +
        </Button>
      </HBox>
    </>
  )
}

export default Recent
