import { isPersisted, type Entity } from '@tunasong/schemas'
import { useEffect, type FC } from 'react'
import invariant from 'tiny-invariant'
import { Box, CircularProgress } from '@mui/material'
import { Y } from '@tunasong/sync-lib'
import { useDBSync, useYDocWebSocket } from '../hooks/index.js'
import { CollabEditor, type CollabEditorProps } from './collab-editor.js'

export interface CollabWebSocketEditorProps
  extends Omit<CollabEditorProps, 'provider' | 'doc' | 'entityId' | 'awareness'> {
  webSocket: WebSocket | null
  /** Use indexedDBSync for entity. @default true */
  indexedDBSync?: boolean
  element: Entity
  doc: Y.Doc
  color: string
  name: string
}

export const CollabWebsocketEditor: FC<CollabWebSocketEditorProps> = props => {
  const { doc, element: entity, webSocket, readOnly, indexedDBSync = true, ...restProps } = props

  invariant(isPersisted(entity), 'CollabWebsocketEditor requires a persisted entity')

  /** Resync with peers (step1) every minute */
  const resyncInterval = 60 * 1000

  const { isSynced, sync } = useDBSync({ entityId: entity.id, doc, disabled: !indexedDBSync })

  const { provider, awareness } = useYDocWebSocket({
    doc,
    entityId: entity.id,
    initialSync: !readOnly,
    resyncInterval,
    localDiffSync: isSynced,
    webSocket,
    receiveOnly: Boolean(readOnly),
  })

  useEffect(() => {
    if (isSynced || !provider) {
      return
    }
    sync().then(() => {
      provider.persistLocalChanges()
    })
  }, [isSynced, provider, sync])

  return isSynced ? (
    <CollabEditor
      key={entity.id}
      {...restProps}
      doc={doc}
      provider={provider}
      awareness={awareness}
      element={entity}
      readOnly={readOnly}
    />
  ) : (
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  )
}

export default CollabWebsocketEditor
