/** MIDI settings  */

import { Box, Button, Divider, Typography } from '@mui/material'
import { usePluginConfig } from '@tunasong/redux'
import { Link, isChrome, isEdge, isSafari, useRedraw } from '@tunasong/ui-lib'
import { type FC } from 'react'
import { useAudioEngine } from '../hooks/audio-engine.js'
import MidiDeviceList from './midi-device-list.js'
import { MidiIOList } from './midi-io-list.js'
import { useMidi } from './midi.hook.js'
import { logger } from '@tunasong/models'

export interface MidiSettingsProps {}

export const MidiSettings: FC<MidiSettingsProps> = props => {
  const {} = props
  const engine = useAudioEngine()
  const { inputs, outputs } = useMidi()
  const redraw = useRedraw()
  const pairBluetoothMidi = async () => {
    await engine?.midi?.btMidi.pairBtMidi()
    redraw()
  }

  const [config] = usePluginConfig('midi')

  logger.debug('MidiSettings', { inputs, outputs, config })

  return (
    <Box>
      <Typography variant="caption">
        We will send and receive to all the MIDI devices we can find. If you have a MIDI device that is not showing up,
        please make sure it is connected and powered on.
      </Typography>
      <Typography variant="h6">MIDI Inputs</Typography>
      <MidiIOList items={inputs} type="input" />

      <Typography variant="h6">MIDI Outputs</Typography>
      <MidiIOList items={outputs} type="output" />

      <Typography variant="h6">Bluetooth MIDI Devices</Typography>

      {config?.btMidi ? (
        <>
          <Typography variant="caption">
            Bluetooth MIDI devices are not yet supported in all browsers. If your device does not appear or you have
            trouble pairing, please try a different browser. The best support is in Chrome.
          </Typography>
          <Divider />
          {isChrome() && config.btMidiAutoConnect ? (
            <Typography variant="caption">
              To enable autoconnect to a Bluetooth MIDI device, you can enable the flag in Chrome (copy to address bar):{' '}
              <br />
              chrome://flags/#enable-web-bluetooth-new-permissions-backend
            </Typography>
          ) : null}
          {isSafari() ? (
            <Typography variant="caption" color="error">
              Safari does not support Bluetooth MIDI devices. You need to connect your device to the computer using the
              OS MIDI settings, e.g., through Audio MIDI Setup on macOS or GarageBand on iOS.
            </Typography>
          ) : null}
          {isEdge() && config.btMidiAutoConnect ? (
            <Typography variant="caption" color="error">
              Edge does not yet support autoconnect to Bluetooth MIDI devices. You need to connect / pair your device
              for each session. Currently Chrome is the best browser for Bluetooth MIDI.
            </Typography>
          ) : null}

          <MidiDeviceList />

          <Button onClick={pairBluetoothMidi}>Pair Bluetooth MIDI device</Button>
        </>
      ) : (
        <Typography variant="caption">
          Bluetooth MIDI is disabled in <Link to="/app/profile?tab=settings"> settings</Link>.
        </Typography>
      )}
    </Box>
  )
}

export default MidiSettings
