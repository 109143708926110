/** Progress control */

import { LinearProgress, type LinearProgressProps } from '@mui/material'
import { forwardRef, useCallback, useEffect, useState } from 'react'

export interface PlayerProgressProps extends LinearProgressProps {
  el?: HTMLAudioElement | null
}

export const PlayerProgress = forwardRef((props: PlayerProgressProps, timelineRef) => {
  const { el, ...restProps } = props

  const [progress, setProgress] = useState(0)

  const handleProgress = useCallback((ev: Event) => {
    const { currentTime, duration } = ev.target as HTMLAudioElement
    const val = (1 - (duration - currentTime) / duration) * 100
    setProgress(Number.isFinite(val) ? val : 0)
  }, [])
  const handleEnded = useCallback(() => {
    setProgress(0)
  }, [])

  /** Events from the player */
  useEffect(() => {
    if (!el) {
      return
    }
    setProgress(0)
    el.addEventListener('seeking', handleProgress)
    el.addEventListener('timeupdate', handleProgress)
    el.addEventListener('ended', handleEnded)
    return () => {
      el.removeEventListener('seeking', handleProgress)
      el.removeEventListener('timeupdate', handleProgress)
      el.removeEventListener('ended', handleEnded)
    }
  }, [el, handleEnded, handleProgress])

  return (
    <LinearProgress
      {...restProps}
      sx={{ ...props.sx, minWidth: 50, width: '100%', height: theme => theme.spacing(1.5) }}
      variant="determinate"
      value={progress}
      ref={timelineRef}
    />
  )
})

export default PlayerProgress
