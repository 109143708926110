import { AppBar, Hidden, Toolbar, type AppBarProps } from '@mui/material'
import { Meter, MidiIndicator, useMixer } from '@tunasong/audio-ui'
import { Offline } from '@tunasong/icons'
import { isOnline } from '@tunasong/models'
import { useSelector } from '@tunasong/redux'
import { BackButton, makeStyles } from '@tunasong/ui-lib'
import cn from 'classnames'
import { forwardRef } from 'react'

interface HeaderProps extends AppBarProps {
  hideOnScrollTarget?: Node
  breadcrumbs?: JSX.Element
  controls?: JSX.Element | (JSX.Element | null)[]
  /** Show status icons - connectivity, MIDI, etc.  */
  showStatus?: boolean
  /** Show Back button */
  showBackButton?: boolean
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: theme.layers?.backgroundColor,
    zIndex: 1,
    gap: theme.spacing(),
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    '& :hover': {
      cursor: 'pointer',
    },
    marginRight: theme.spacing(),
  },
  smallButton: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 32,
  },
}))

export const Header = forwardRef<HTMLElement, HeaderProps>((props, ref) => {
  const { showBackButton = true, color = 'transparent', controls, breadcrumbs, showStatus = true, ...restProps } = props
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { classes } = useStyles()
  const mainBus = useMixer().getBus('monitor')
  const isPlaying = useSelector(state => state.audio.player.isPlaying)

  /** Show spinner if loading songs */
  if (!isLoggedIn) {
    return null
  }

  return (
    <AppBar color={color} {...restProps} ref={ref}>
      <Toolbar className={classes.root} variant="dense">
        {showBackButton ? <BackButton className={cn(classes.link, classes.smallButton)} /> : null}
        <Hidden smDown>{breadcrumbs}</Hidden>

        {showStatus ? (
          <>
            {isOnline() ? null : <Offline titleAccess="Offline" color="secondary" />}
            {/* the meter uses an useAnimationFrameLoop hook and is costly to run without any playback */}
            {isPlaying ? <Meter color="secondary" controller={mainBus} orientation="vertical" /> : null}
            <MidiIndicator />
          </>
        ) : null}

        {controls}
      </Toolbar>
    </AppBar>
  )
})
