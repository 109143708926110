/** Extract entityLinks in the specified range, and return the entities */

import { type EntityLink, isEntityLink, type TunaElement } from '@tunasong/models'
import { type TunaEditor } from '@tunasong/plugin-lib'
import { useEntitiesById } from '@tunasong/redux'
import { useMemo } from 'react'
import { EntityQueries } from '../editor/queries/entities.js'
import { RangeQueries } from '../editor/queries/range.js'

export const useFromEntityLinks = (editor: TunaEditor | null, at = editor ? RangeQueries.all(editor) : undefined) => {
  /** Extract the links, get the linked entities by ID */
  const entityLinks: TunaElement<EntityLink>[] = useMemo(
    () => (editor ? (EntityQueries.find(editor, isEntityLink, at).map(e => e[0]) as never) : []),
    [editor, at]
  )
  const { entities } = useEntitiesById(entityLinks.map(e => e.ref.id).filter(Boolean) as string[])
  return entities
}
