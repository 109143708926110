import defaultQuery from './default.js'
import getId from './get-id.js'
import recentQuery from './recent.js'
import relatedContent from './related-content.js'
import sharedQuery from './shared.js'
import templatesQuery from './templates.js'
import typeQuery from './type-query.js'

/**
 * @see https://www.elastic.co/guide/en/elasticsearch/reference/7.x/query-dsl.html
 */

/**
 * @note The queries are subjected to "authorization filter" server-side, so not all ES queries will be valid.
 * @see authorizeQuery in sls-api
 */
export const allQueries = {
  default: defaultQuery,
  relatedContent,
  getId,
  type: typeQuery,
  recent: recentQuery,
  shared: sharedQuery,
  templates: templatesQuery,
}
export type QueryType = keyof typeof allQueries
