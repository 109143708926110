import manifest from '@tunasong/manifest'
import type { SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'
/** Default full-text query for entities */
const query = ({ query, size = 100, filter }: QueryProps): SearchRequest =>
  ({
    index: 'entities',
    size,
    from: 0,
    /** embeddings are large - don't return them by default */
    _source_excludes: ['embedding', 'acls'],
    body: {
      query: {
        function_score: {
          /**
           * We want to boost exact matches
           *
           * https://discuss.elastic.co/t/how-to-increase-score-for-exact-word-phrase-match-in-elastic-search/184901
           * */

          query: {
            bool: {
              must: [
                {
                  query_string: {
                    query: `${query}*`,
                    fields: ['name^10', 'tags^2', 'description^3', 'type^5', 'freetext'],
                  },
                },
              ],

              must_not: {
                terms: { type: manifest.search.excludeFromDefault },
              },
              filter: [
                {
                  bool: {
                    must_not: [
                      // additional filters here
                      { term: { trash: true } },
                    ],
                    must: [
                      // additional filters here

                      filter?.types && filter.types.length > 0
                        ? {
                            terms: { type: filter.types },
                          }
                        : null,
                      filter?.tags && filter.tags.length > 0 ? { terms: { tags: filter.tags } } : null,
                    ].filter(Boolean),
                  },
                },
              ],
            },
          },
          functions: [
            {
              /** Boost songs */
              filter: {
                term: {
                  type: 'song',
                },
              },
              weight: 3,
            },
            {
              /** Boost pages */
              filter: {
                term: {
                  type: 'page',
                },
              },
              weight: 2,
            },
            {
              /** Boost folders */
              filter: {
                term: {
                  type: 'folder',
                },
              },
              weight: 1.5,
            },
            {
              filter: {
                range: { updatedAt: { gte: 'now-1m', lte: 'now' } },
              },
              weight: 5,
            },
            {
              filter: {
                range: { updatedAt: { gte: 'now-6m', lt: 'now-1m' } },
              },
              weight: 2,
            },
          ],
          boost_mode: 'multiply',
        },
      },
    },
  } satisfies SearchRequest)

export default query
